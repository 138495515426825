<template>
  <multiselect v-model="sel"
               class="has-tags"
               tag-placeholder="Add this as new tag"
               :placeholder="placeholder"
               label="code"
               track-by="code"
               :searchable="is_addable"
               :options="options"
               :multiple="true"
               :taggable="true"
               :loading="isLoading"
               :internal-search="!autocomplete"
               @search-change="asyncFind"
               @input="valueUpdated"
               @tag="addTag"
  >
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span>{{ props.option.code }}</span>
        <span v-if="props.option.description">- {{ props.option.description }}</span>
      </div>
    </template>
  </multiselect>

</template>

<script>
import Multiselect from "vue-multiselect"

export default {
  name: "PTagInput",
  data() {
    return {
      isLoading: false,
      sel: [],
      options: []
    }
  },
  components: {Multiselect},
  props: {
    value: {
      type: [Array],
      required: false,
      default: () => []
    },
    autocomplete: {
      type: Function,
      required: false,
      default: null
    },
    addable: {
      type: Boolean,
      required: false,
      default: false
    },
    allowed_tags: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:value', 'input'],
  async mounted() {
    this.init()
  },
  watch: {
    value: function () {
      this.init()
    },
    allowed_tags: function () {
      this.init()
    }
  },
  computed: {
    is_addable() {
      return !!(this.autocomplete || this.addable || this.allowed_tags.length <= 0)
    },
    placeholder() {
      if (this.is_addable)
        return 'Search or add a tag'
      else
        return 'Search a tag'
    }
  },
  methods: {
    init() {
      let vals = this.value
      if (!vals) vals = []
      if (!Array.isArray(vals))
        vals = vals.split(',')
      this.sel = vals.map(tag => {
        return {code: tag.trim()}
      })
      if (this.allowed_tags)
        this.options = this.allowed_tags.map(tag => {
          return {code: tag, description: ''}
        })
    },
    addTag(tagInput) {
      // We need to clean the newTag, we want basic characters, numbers, spaces should be replaced with "-"
      // We don't want to start or end with "-"
      let newTag = tagInput.replace(/[^a-zA-Z0-9]/g, '-').replace(/^-+|-+$/g, '').toLowerCase()

      // If the tag starts with "-" this is an anti-tag, we should keep it only if it's explicit and not a side effect of "#tag" or "!!!tag" or similar
      if (tagInput[0] === '-') newTag = `-${newTag}`

      const tag = {
        description: '',
        code: newTag
      }
      this.options.push(tag)
      this.sel.push(tag)
      this.valueUpdated()
    },
    async asyncFind(query) {
      if (!this.autocomplete) return

      this.isLoading = true
      this.options = await this.autocomplete(query)
      this.isLoading = false
    },
    valueUpdated() {
      const tags = this.sel.map(tag => tag.code)
      this.$emit('update:value', tags)
      this.$emit('input', tags)
    }
  }
}
</script>