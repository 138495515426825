<template>
  <CRow form>
    <CCol col="12">
      <CRow class="align-items-center justify-content-between mb-2">
        <CCol col="12">
          <label v-if="label">{{ label }}</label>
          <slot name="label"></slot>
        </CCol>
      </CRow>
    </CCol>
    <CCol col>
      <ckeditor :editor="editor" v-model="text" :placeholder="placeholder" :config="editorConfig"
                @input="onChangeValue"/>
    </CCol>
    <CCol col="auto" v-if="hasLangOptions">
      <PDropDown show-item-selector
                 :placement="'bottom-end'"
                 @change="onChangeLang" :items="langOptions"/>
    </CCol>
  </CRow>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import PDropDown from "@/domain/core/components/PDropDown.vue";
import {mapGetters} from "vuex";

export default {
  name: "PTranslatableCkeditor",
  components: {
    PDropDown,
    ckeditor: CKEditor.component
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    langs: {
      type: Array,
      required: false
    }
  },
  emits: ['update:value', 'input'],
  data() {
    return {
      texts: {'it': ''},
      text: '',
      lang: 'it',
      lang_options: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
        link: {
          defaultProtocol: 'https://',
          decorators: {
            openInNewTab: {
              defaultValue: true,
              mode: 'manual',
              label: this.$pgettext('ckeditor', 'Open in a new tab'),
              attributes: {
                target: '_blank',
              }
            }
          }
        }
      },
    }
  },
  watch: {
    value: function () {
      this.init()
    }, langs: function () {
      this.init()
    }
  },
  computed: {
    ...mapGetters('login', ['userLanguage']),
    langOptions() {
      const langs = this.langs ?? process.env.VUE_APP_SUPPORTED_LANGS.split(',')
      return langs.map(lang => {
        return {label: lang.toUpperCase(), value: lang}
      })
    },
    hasLangOptions() {
      return this.langOptions.length > 1
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.texts = this.value ?? {}
      this.lang = this.userLanguage
      if (!this.texts[this.lang]) {
        // Find the first text with a lang
        for (const lang in this.texts) {
          if (this.texts[lang]) {
            this.lang = lang
            break
          }
        }
      }
      this.text = this.texts[this.lang] ?? ''
    },
    onChangeValue() {
      this.texts[this.lang] = this.text
      this.$emit('update:value', this.texts)
      this.$emit('input', this.texts)
    },
    onChangeLang(newLang) {
      if (newLang instanceof Object)
        return
      this.onChangeValue()
      this.lang = newLang
      this.text = this.texts[this.lang] ?? ''
    }
  }
}
</script>

<style>
.ck-editor__editable {
  min-height: 160px !important;
}
</style>